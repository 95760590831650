
@font-face {
  font-family: 'Tandelle';
  src: url(/fonts/Tandelle.eeb6e2a.woff2) format("woff2"), url(/fonts/Tandelle.9e07c1f.woff) format("woff"), url(/fonts/Tandelle.561520b.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
@font-face {
  font-family: 'Tandelle';
  src: url(/fonts/Tandelle-Bold.8549b5b.woff2) format("woff2"), url(/fonts/Tandelle-Bold.cae3afe.woff) format("woff"), url(/fonts/Tandelle-Bold.69ef918.ttf) format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: block;
}
@font-face {
  font-family: "DIN Pro";
  src: url(/fonts/DINPro.26790dc.otf);
  font-weight: 400;
  font-display: fallback;
}
@font-face {
  font-family: "DIN Pro";
  src: url(/fonts/DINPro-Light.001e05a.otf);
  font-weight: 300;
  font-display: fallback;
}
@font-face {
  font-family: "DIN Pro";
  src: url(/fonts/DINPro-Medium.90d4bd8.otf);
  font-weight: 500;
  font-display: fallback;
}
@font-face {
  font-family: "DIN Pro";
  src: url(/fonts/DINPro-Bold.37684a0.otf);
  font-weight: 600;
  font-display: fallback;
}
@font-face {
  font-family: "DIN Pro";
  src: url(/fonts/DINPro-MediumItalic.a6489cb.otf);
  font-weight: 500;
  font-style: italic;
  font-display: fallback;
}
body {
  background-image: url(/img/noise.7d1bba1.png);
  background-repeat: repeat;
  background-color: #000;
  margin: 0;
  height: 100%;
  overflow: hidden;
}
body div, body p, body span, body a {
    -webkit-font-smoothing: antialiased;
}
body h1, body h2, body h3, body h4 {
    margin: 0;
}
body #app {
    width: 100%;
    height: 100%;
}
body #app .container {
      width: 100%;
      height: 100%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
}
body a {
    text-decoration: none;
}
body p {
    margin: 0;
}
.paragraph {
  font-family: "DIN Pro";
  font-size: 16px;
  letter-spacing: 0.4px;
  line-height: 1.5;
  color: #fff;
}
@media screen and (max-width: 640px) and (orientation: portrait) {
.paragraph {
      font-size: 14px;
      letter-spacing: 0.35px;
}
}
.subtitle {
  font-family: "DIN Pro";
  font-size: 16px;
  letter-spacing: 0.8px;
  text-transform: uppercase;
  color: #fff;
  opacity: 0.4;
  white-space: nowrap;
}
@media screen and (max-width: 640px) {
.subtitle {
      font-size: 12px;
      letter-spacing: 0.4px;
}
}
.subtitle.date {
    font-size: 18px;
}
.title {
  font-family: "Tandelle";
  font-weight: bold;
  font-size: 120px;
  letter-spacing: 14px;
  text-transform: uppercase;
  color: transparent;
  -webkit-text-stroke: #fff 1.5px;
  text-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}
@media screen and (min-width: 1680px) {
.title {
      font-size: 150px;
}
}
@media screen and (max-width: 640px) {
.title {
      font-size: 64px;
      letter-spacing: 6px;
      -webkit-text-stroke: #fff 1px;
}
}
@media screen and (max-width: 325px) and (orientation: portrait) {
.title {
      font-size: 60px;
}
}
.bigTitle {
  font-family: "Tandelle";
  font-weight: bold;
  font-size: 150px;
  letter-spacing: 14px;
  text-transform: uppercase;
  color: transparent;
  -webkit-text-stroke: #fff 1.5px;
  text-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
}
@media screen and (max-width: 1300px) {
.bigTitle {
      font-size: 120px;
}
}
@media screen and (max-width: 1024px) {
.bigTitle {
      font-size: 100px;
}
}
@media screen and (max-width: 640px) {
.bigTitle {
      font-size: 56px;
      -webkit-text-stroke: #fff 1px;
      letter-spacing: 7px;
      white-space: nowrap;
}
}
.flexCenter {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.none {
  display: none;
}
#nav {
  width: calc(100vw - 140px);
  margin: 40px 0px 0px 0px;
  position: fixed;
  left: 70px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  z-index: 3;
}
@media screen and (max-width: 640px) {
#nav {
      margin-top: 32px;
      width: calc(100vw - 48px);
      left: 24px;
}
#nav span {
        display: none;
}
}
#nav .icon {
    font-family: "Tandelle";
    font-size: 20px;
    letter-spacing: 1.4px;
    color: #fff;
    text-decoration: none;
    cursor: pointer;
}
#nav .about {
    font-family: "DIN Pro";
    font-size: 16px;
    color: #fff;
    opacity: 0.6;
    text-decoration: none;
    -webkit-transition: 0.4s ease;
    transition: 0.4s ease;
    cursor: pointer;
}
#nav .about:hover {
      opacity: 1;
}
#nav .close__project {
    position: fixed;
    top: 40px;
    left: calc(50vw - 9px);
    cursor: pointer;
}
@media screen and (max-width: 640px) {
#nav .close__project {
        top: inherit;
}
}
textarea {
  position: absolute;
  left: -9999px;
}

.loading[data-v-2710bd6e] {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0px;
  left: 0px;
  background-image: url(/img/noise.7d1bba1.png);
  background-repeat: none;
  background-color: #000;
  z-index: 1000;
}
@media screen and (max-width: 640px) and (orientation: portrait) {
.loading[data-v-2710bd6e] {
      height: 100%;
}
}
.loading .loading__container[data-v-2710bd6e] {
    width: 100%;
    height: 100%;
}
.loading .loading__container .loading__bigCircle[data-v-2710bd6e] {
      width: 640px;
      height: 640px;
      border-radius: 1000px;
      border: 1px solid #ffffff;
      position: relative;
      z-index: 3;
}
@media screen and (max-width: 640px) and (orientation: portrait) {
.loading .loading__container .loading__bigCircle[data-v-2710bd6e] {
          width: 320px;
          height: 320px;
}
}
.loading .loading__container .loading__bigCircle .loading__smallCircle[data-v-2710bd6e] {
        width: 600px;
        height: 600px;
        border-radius: 1000px;
        border: 1px solid #ffffff;
}
@media screen and (max-width: 640px) and (orientation: portrait) {
.loading .loading__container .loading__bigCircle .loading__smallCircle[data-v-2710bd6e] {
            width: 300px;
            height: 300px;
}
}
.loading .loading__container .loading__bigCircle .loading__smallCircle .loading__number[data-v-2710bd6e] {
          font-family: "DIN Pro";
          font-size: 240px;
          color: #ffffff;
}
@media screen and (max-width: 640px) and (orientation: portrait) {
.loading .loading__container .loading__bigCircle .loading__smallCircle .loading__number[data-v-2710bd6e] {
              font-size: 120px;
}
}
.loading .loading__container .line[data-v-2710bd6e] {
      position: absolute;
      z-index: 2;
}
.loading .loading__container .chart[data-v-2710bd6e] {
      position: absolute;
      -webkit-transform: rotate(-90deg);
              transform: rotate(-90deg);
      border-radius: 50%;
      display: block;
}
.loading .loading__container .chart circle[data-v-2710bd6e] {
        fill: transparent;
        stroke: rgba(255, 255, 255, 0.1);
        stroke-width: 1920;
        stroke-dasharray: 6030 6030;
        -webkit-animation: pieAnimation-data-v-2710bd6e 0.75s linear infinite;
                animation: pieAnimation-data-v-2710bd6e 0.75s linear infinite;
}
@-webkit-keyframes pieAnimation-data-v-2710bd6e {
0% {
    stroke-dasharray: 0 6030;
}
100% {
    stroke-dasharray: 6030 6030;
}
}
@keyframes pieAnimation-data-v-2710bd6e {
0% {
    stroke-dasharray: 0 6030;
}
100% {
    stroke-dasharray: 6030 6030;
}
}

#about__responsive[data-v-48de94bf] {
  height: 100vh;
  margin: 0px 24px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
#about__responsive .bigTitle[data-v-48de94bf] {
    margin-top: 4vh;
}
#about__responsive .paragraph[data-v-48de94bf] {
    margin-top: 24px;
}
#about__responsive .socials[data-v-48de94bf] {
    width: calc(100vw - 48px);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: absolute;
    bottom: 24px;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
}
#about__responsive .socials .paragraph[data-v-48de94bf] {
      opacity: 0.5;
      -webkit-transition: 0.4s ease;
      transition: 0.4s ease;
      position: relative;
}
#about__responsive .socials .paragraph .mailCTA[data-v-48de94bf] {
        width: 300px;
        text-align: right;
        position: absolute;
        top: -32px;
        right: 0px;
        opacity: 0;
        -webkit-transition: 0.4s ease;
        transition: 0.4s ease;
}

.scene__container[data-v-56ec5c9f] {
  width: 100vw;
  height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  position: absolute;
  top: 0px;
  left: 0px;
}
@media screen and (max-width: 640px) and (orientation: portrait) {
.scene__container[data-v-56ec5c9f] {
      height: 100%;
      display: grid;
      grid-template-rows: 32px 0.9fr calc(100vw - 80px) 1.1fr 32px;
}
}
.scene[data-v-56ec5c9f] {
  width: calc(100vh / 1.5);
  height: calc(100vh / 1.5);
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border-radius: calc(100vh / 15);
  -webkit-transition: width 1s cubic-bezier(0.86, -0.06, 0.23, 0.99);
  transition: width 1s cubic-bezier(0.86, -0.06, 0.23, 0.99);
}
@media screen and (max-width: 640px) and (orientation: portrait) {
.scene[data-v-56ec5c9f] {
      width: calc(100vw - 80px);
      height: calc(100vw - 80px);
      border-radius: calc((100vw - 80px) / 10);
      grid-row-start: 3;
      grid-row-end: 4;
}
}
.scene .scene__slide[data-v-56ec5c9f] {
    width: 100%;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    border-radius: calc(100vh / 15);
    overflow: hidden;
    -ms-flex-negative: 0;
        flex-shrink: 0;
    -webkit-transition: width 1s cubic-bezier(0.86, -0.06, 0.23, 0.99), -webkit-transform 1.15s cubic-bezier(0.86, -0.06, 0.23, 0.99);
    transition: width 1s cubic-bezier(0.86, -0.06, 0.23, 0.99), -webkit-transform 1.15s cubic-bezier(0.86, -0.06, 0.23, 0.99);
    transition: transform 1.15s cubic-bezier(0.86, -0.06, 0.23, 0.99), width 1s cubic-bezier(0.86, -0.06, 0.23, 0.99);
    transition: transform 1.15s cubic-bezier(0.86, -0.06, 0.23, 0.99), width 1s cubic-bezier(0.86, -0.06, 0.23, 0.99), -webkit-transform 1.15s cubic-bezier(0.86, -0.06, 0.23, 0.99);
    position: relative;
}
@media screen and (max-width: 640px) and (orientation: portrait) {
.scene .scene__slide[data-v-56ec5c9f] {
        border-radius: calc((100vw - 80px) / 10);
}
}
.scene .scene__slide video[data-v-56ec5c9f] {
      height: 100%;
      border-radius: calc(100vh / 15);
      width: auto;
      -o-object-fit: cover;
         object-fit: cover;
      opacity: 0;
      position: absolute;
}
.scene.scene__project__transition[data-v-56ec5c9f] {
    width: 70%;
}
@media screen and (max-width: 640px) and (orientation: portrait) {
.scene.scene__project__transition[data-v-56ec5c9f] {
        width: calc(100vw - 80px);
        -webkit-transition: inherit;
        transition: inherit;
}
}

.home[data-v-39f1a074] {
  width: 100vw;
  height: 100vh;
  position: relative;
  display: grid;
  grid-template-rows: 32px 1fr calc(100vh / 1.5) 1fr 32px;
  z-index: 2;
}
@media screen and (max-width: 640px) and (orientation: portrait) {
.home[data-v-39f1a074] {
      grid-template-rows: 32px 0.9fr calc(100vw - 80px) 1.1fr 32px;
      position: fixed;
      height: 100%;
}
}
.home .content[data-v-39f1a074] {
    display: grid;
    grid-template-columns: 1fr 16px calc(100vh / 1.5) 16px 1fr;
    grid-row-start: 3;
    grid-row-end: 4;
    position: relative;
}
@media screen and (max-width: 640px) and (orientation: portrait) {
.home .content[data-v-39f1a074] {
        grid-template-columns: 1fr 4px calc(100vw - 80px) 4px 1fr;
}
}
.home .content .content__title[data-v-39f1a074] {
      grid-column-start: 1;
      grid-column-end: 4;
      grid-row-start: 1;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      margin-top: calc(100vh/6);
      margin-left: calc(100vw / 5);
      position: absolute;
      z-index: 2;
      cursor: pointer;
}
@media screen and (max-width: 640px) and (orientation: portrait) {
.home .content .content__title[data-v-39f1a074] {
          margin-top: 13%;
          margin-left: 16px;
}
}
.home .content .content__title .title[data-v-39f1a074] {
        line-height: 92px;
        -ms-flex-item-align: start;
            align-self: flex-start;
}
@media screen and (min-width: 1680px) {
.home .content .content__title .title[data-v-39f1a074] {
            line-height: 112px;
}
}
.home .content .content__title .title .title__second__line[data-v-39f1a074] {
          margin-top: 22px;
}
@media screen and (max-width: 640px) {
.home .content .content__title .title .title__second__line[data-v-39f1a074] {
              margin-top: 0px;
}
}
.home .content .content__title .title .title__first__line[data-v-39f1a074], .home .content .content__title .title .title__second__line[data-v-39f1a074] {
          overflow: hidden;
}
@media screen and (max-width: 640px) {
.home .content .content__title .title[data-v-39f1a074] {
            line-height: 1;
}
}
.home .content .content__title .title__number[data-v-39f1a074] {
        font-family: "DIN Pro";
        font-size: 18px;
        color: rgba(255, 255, 255, 0.4);
        margin-right: 8px;
        -ms-flex-item-align: start;
            align-self: flex-start;
        overflow: hidden;
}
.home .content .content__title .title__number div[data-v-39f1a074] {
          -webkit-transform: translateY(100px);
                  transform: translateY(100px);
          -webkit-transition: all 1s cubic-bezier(0.86, -0.06, 0.23, 0.99);
          transition: all 1s cubic-bezier(0.86, -0.06, 0.23, 0.99);
}
@media screen and (max-width: 640px) {
.home .content .content__title .title__number[data-v-39f1a074] {
            display: none;
}
}
.home .content .type[data-v-39f1a074] {
      grid-column-start: 5;
      grid-row-start: 1;
      position: absolute;
      left: 0;
      top: calc(100vh / 15);
      -webkit-transform: rotate(90deg);
              transform: rotate(90deg);
      -webkit-transform-origin: bottom left;
              transform-origin: bottom left;
      -webkit-transition: all 1s cubic-bezier(0.86, -0.06, 0.23, 0.99);
      transition: all 1s cubic-bezier(0.86, -0.06, 0.23, 0.99);
}
@media screen and (max-width: 640px) {
.home .content .type[data-v-39f1a074] {
          top: calc((100vw - 80px) / 10);
}
}
.home .content .date[data-v-39f1a074] {
      grid-column-start: 1;
      grid-column-end: 2;
      grid-row-start: 1;
      position: absolute;
      right: 0;
      bottom: calc(100vh / 15 + 38px);
      -webkit-transform: rotate(-90deg);
              transform: rotate(-90deg);
      -webkit-transform-origin: bottom right;
              transform-origin: bottom right;
      -webkit-transition: all 1s cubic-bezier(0.86, -0.06, 0.23, 0.99);
      transition: all 1s cubic-bezier(0.86, -0.06, 0.23, 0.99);
}
@media screen and (max-width: 640px) {
.home .content .date[data-v-39f1a074] {
          font-size: 12px;
          bottom: calc(((100vw - 80px) / 10) + 26px);
}
}
.home .content .scene__hotspot[data-v-39f1a074] {
      grid-column-start: 3;
      grid-column-end: 4;
      cursor: pointer;
}
.home .subtitle[data-v-39f1a074] {
    opacity: 0;
}

.footer {
  grid-row-start: 4;
  grid-row-end: 5;
  width: calc(100vw - 140px);
  margin: 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  -webkit-transition: all 1s cubic-bezier(0.86, -0.06, 0.23, 0.99);
  transition: all 1s cubic-bezier(0.86, -0.06, 0.23, 0.99);
}
@media screen and (max-width: 640px) and (orientation: portrait) {
.footer {
      width: calc(100vw - 48px);
      display: grid;
      grid-template-rows: 1fr 1fr;
      grid-template-columns: 1fr;
}
}
.footer .slider {
    width: 104px;
    height: 12px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-transition: all 1s cubic-bezier(0.86, -0.06, 0.23, 0.99);
    transition: all 1s cubic-bezier(0.86, -0.06, 0.23, 0.99);
}
@media screen and (max-width: 640px) and (orientation: portrait) {
.footer .slider {
        margin: 0 auto;
        width: 150px;
}
}
.footer .slider .slide {
      width: 8px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
}
@media screen and (max-width: 640px) and (orientation: portrait) {
.footer .slider .slide {
          width: 12px;
}
}
.footer .slider .slide .circle {
        width: 8px;
        height: 8px;
        border-radius: 4px;
        background-color: #fff;
        opacity: 0.2;
        cursor: pointer;
        -webkit-transition: 0.4s cubic-bezier(0.63, 0.01, 0.11, 0.98);
        transition: 0.4s cubic-bezier(0.63, 0.01, 0.11, 0.98);
}
@media screen and (max-width: 640px) and (orientation: portrait) {
.footer .slider .slide .circle {
            width: 12px;
            height: 12px;
            border-radius: 6px;
}
}
.footer .slider .slide .circle:hover {
          opacity: 0.7;
}
.footer .slider .slide .active {
        width: 10px;
        height: 10px;
        border-radius: 7px;
        border: 1px #fff solid;
        background-color: initial;
        opacity: 0.7;
        -ms-flex-negative: 0;
            flex-shrink: 0;
}
.footer .socials {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
@media screen and (max-width: 640px) and (orientation: portrait) {
.footer .socials {
        grid-row-start: 2;
        -webkit-box-pack: justify;
            -ms-flex-pack: justify;
                justify-content: space-between;
}
}
.footer .socials .paragraph {
      margin-right: 40px;
      opacity: 0.2;
      -webkit-transition: 0.4s ease;
      transition: 0.4s ease;
      position: relative;
}
@media screen and (max-width: 640px) and (orientation: portrait) {
.footer .socials .paragraph {
          margin: 0;
          grid-row-start: 2;
          -webkit-box-pack: justify;
              -ms-flex-pack: justify;
                  justify-content: space-between;
}
}
.footer .socials .paragraph .mailCTA {
        width: 300px;
        text-align: right;
        position: absolute;
        top: -32px;
        right: 0px;
        opacity: 0;
        -webkit-transition: 0.4s ease;
        transition: 0.4s ease;
}
.footer .socials .paragraph:last-child {
        margin-right: 0px;
        cursor: pointer;
}
.footer .socials .paragraph:last-child:hover .mailCTA {
          opacity: 1;
}
.footer .socials .paragraph:hover {
        opacity: 0.6;
}

.about[data-v-67be2ea4] {
  position: relative;
}
.about__hello[data-v-67be2ea4] {
    width: 100vw;
    height: 100vh;
    display: grid;
    grid-template-columns: 70px calc(100vh / 1.5) 64px 1fr 70px;
    grid-template-rows: 1fr calc(100vh/3) calc(100vh/3) 1fr;
}
@media screen and (max-width: 640px) and (orientation: portrait) {
.about__hello[data-v-67be2ea4] {
        height: 100%;
        grid-template-columns: 1fr calc(100vw - 80px) 1fr;
        grid-template-rows: 120px calc(100vw - 80px) 8px 24px 1fr;
}
}
.about__hello__photo[data-v-67be2ea4] {
      grid-column-start: 2;
      grid-column-end: 3;
      grid-row-start: 2;
      grid-row-end: 4;
      border-radius: calc(100vh/15);
      overflow: hidden;
}
@media screen and (max-width: 640px) {
.about__hello__photo[data-v-67be2ea4] {
          grid-row-end: 3;
          border-radius: calc((100vw - 80px) / 10);
}
}
.about__hello__photo__container[data-v-67be2ea4] {
        width: 100%;
        height: 100%;
        overflow: hidden;
        border-radius: calc(100vh/15);
        -webkit-mask-image: -webkit-radial-gradient(white, black);
}
@media screen and (max-width: 640px) {
.about__hello__photo__container[data-v-67be2ea4] {
            border-radius: calc((100vw - 80px) / 10);
}
}
.about__hello__photo__container video[data-v-67be2ea4] {
          width: auto;
          height: 100%;
}
.about__hello__hey[data-v-67be2ea4] {
      grid-column-start: 2;
      grid-column-end: 3;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: end;
          -ms-flex-align: end;
              align-items: flex-end;
      opacity: 0;
      -webkit-transition: opacity 1.15s cubic-bezier(0.86, -0.06, 0.23, 0.99);
      transition: opacity 1.15s cubic-bezier(0.86, -0.06, 0.23, 0.99);
}
.about__hello__hey div[data-v-67be2ea4] {
        margin-left: calc(100vh/15);
        margin-bottom: 16px;
}
@media screen and (max-width: 640px) and (orientation: portrait) {
.about__hello__hey div[data-v-67be2ea4] {
            margin-bottom: 8px;
            margin-left: calc((100vw - 80px) / 10);
}
}
.about__hello__notMe[data-v-67be2ea4] {
      grid-column-start: 2;
      grid-column-end: 3;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: end;
          -ms-flex-pack: end;
              justify-content: flex-end;
      opacity: 0;
      -webkit-transition: opacity 1.15s cubic-bezier(0.86, -0.06, 0.23, 0.99);
      transition: opacity 1.15s cubic-bezier(0.86, -0.06, 0.23, 0.99);
}
@media screen and (max-width: 640px) and (orientation: portrait) {
.about__hello__notMe[data-v-67be2ea4] {
          grid-row-start: 4;
          grid-row-end: 5;
}
}
.about__hello__notMe div[data-v-67be2ea4] {
        margin-right: calc(100vh/15);
        margin-top: 16px;
}
@media screen and (max-width: 640px) and (orientation: portrait) {
.about__hello__notMe div[data-v-67be2ea4] {
            margin-top: 0px;
            margin-right: calc((100vw - 80px) / 10);
}
}
.about__hello__frenchTouch[data-v-67be2ea4] {
      grid-column-start: 3;
      grid-column-end: 6;
      grid-row-start: 2;
      grid-row-end: 3;
      margin-top: 5%;
      margin-left: -20%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      position: relative;
      z-index: 2;
}
@media screen and (max-width: 640px) and (orientation: portrait) {
.about__hello__frenchTouch[data-v-67be2ea4] {
          margin-top: 0;
          margin-left: 0;
          grid-column-start: 1;
          grid-column-end: 4;
}
}
.about__hello__frenchTouch div[data-v-67be2ea4] {
        overflow: hidden;
}
@media screen and (max-width: 640px) and (orientation: portrait) {
.about__hello__frenchTouch div[data-v-67be2ea4] {
            position: absolute;
            margin-top: 10%;
            margin-left: 16px;
            top: 0;
}
}
.about__hello__presentation[data-v-67be2ea4] {
      grid-column-start: 4;
      grid-column-end: 5;
      grid-row-start: 3;
      grid-row-end: 4;
      font-size: 20px;
      opacity: 0;
      -webkit-transition: opacity 1.15s cubic-bezier(0.86, -0.06, 0.23, 0.99);
      transition: opacity 1.15s cubic-bezier(0.86, -0.06, 0.23, 0.99);
}
@media screen and (max-width: 640px) and (orientation: portrait) {
.about__hello__presentation[data-v-67be2ea4] {
          grid-column-start: 2;
          grid-column-end: 3;
          grid-row-start: 5;
          grid-row-end: 6;
          font-size: 14px;
          margin-top: 16px;
}
}
@media screen and (min-width: 1680px) {
.about__hello__presentation[data-v-67be2ea4] {
          width: 80%;
}
}
.about__hello__presentation .internship[data-v-67be2ea4] {
        margin-top: 30px;
        margin-bottom: calc(100vh/15);
        font-weight: 500;
}
@media screen and (max-width: 640px) and (orientation: portrait) {
.about__hello__presentation .internship[data-v-67be2ea4] {
            margin-top: 20px;
}
}
.about__me[data-v-67be2ea4] {
    margin-top: 40px;
    margin-left: calc(70px + 100vh/15);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
@media screen and (max-width: 640px) and (orientation: portrait) {
.about__me[data-v-67be2ea4] {
        width: calc(100vw - 80px);
        margin: auto;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
        -webkit-box-pack: justify;
            -ms-flex-pack: justify;
                justify-content: space-between;
}
}
.about__me__items[data-v-67be2ea4] {
      margin-right: 96px;
}
@media screen and (max-width: 640px) and (orientation: portrait) {
.about__me__items[data-v-67be2ea4] {
          margin-right: 0px;
          width: 46%;
}
}
@media screen and (min-width: 1680px) {
.about__me__items[data-v-67be2ea4] {
          margin-right: 120px;
}
}
@media screen and (max-width: 640px) and (orientation: portrait) {
.about__me__items[data-v-67be2ea4]:last-child {
          margin-top: 16px;
}
}
.about__me__items .subtitle[data-v-67be2ea4] {
        margin-bottom: 16px;
}
.about__me__items__single[data-v-67be2ea4] {
        margin-bottom: 25px;
        font-family: 'Din Pro';
        color: #fff;
}
@media screen and (max-width: 640px) and (orientation: portrait) {
.about__me__items__single[data-v-67be2ea4] {
            margin-bottom: 14px;
}
}
.about__me__items__single__content[data-v-67be2ea4] {
          font-size: 20px;
}
@media screen and (max-width: 640px) and (orientation: portrait) {
.about__me__items__single__content[data-v-67be2ea4] {
              font-size: 14px;
}
}
.about__me__items__single__subtitle[data-v-67be2ea4] {
          opacity: 0.3;
          font-size: 16px;
          margin-top: 4px;
}
@media screen and (max-width: 640px) and (orientation: portrait) {
.about__me__items__single__subtitle[data-v-67be2ea4] {
              font-size: 12px;
}
}
.about__me__items__single__link[data-v-67be2ea4] {
          font-size: 16px;
}
@media screen and (max-width: 640px) and (orientation: portrait) {
.about__me__items__single__link[data-v-67be2ea4] {
              font-size: 12px;
}
}
.about__me__items__single__link a[data-v-67be2ea4] {
            color: #fff;
            position: relative;
            text-decoration: underline;
            -webkit-transition: 0.4s ease;
            transition: 0.4s ease;
            opacity: 0.3;
}
.about__me__items__single__link a[data-v-67be2ea4]:hover {
              opacity: 0.5;
}
.about__contact[data-v-67be2ea4] {
    margin-top: 88px;
    margin-left: calc(70px + 100vh/15);
    margin-bottom: 128px;
}
@media screen and (max-width: 640px) and (orientation: portrait) {
.about__contact[data-v-67be2ea4] {
        width: calc(100vw - 80px);
        margin: 32px auto 32px auto;
}
}
.about__contact .subtitle[data-v-67be2ea4] {
      margin-bottom: 16px;
}
.about__contact__paragraph[data-v-67be2ea4] {
      font-size: 20px;
}
@media screen and (max-width: 640px) and (orientation: portrait) {
.about__contact__paragraph[data-v-67be2ea4] {
          font-size: 14px;
}
}
.socials[data-v-67be2ea4] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: fixed;
  bottom: 32px;
  right: 70px;
}
@media screen and (max-width: 640px) and (orientation: portrait) {
.socials[data-v-67be2ea4] {
      position: relative;
      width: 100%;
      right: 0px;
      bottom: 0;
      -ms-flex-pack: distribute;
          justify-content: space-around;
}
}
.socials .paragraph[data-v-67be2ea4] {
    margin-right: 40px;
    opacity: 0.2;
    -webkit-transition: 0.4s ease;
    transition: 0.4s ease;
    position: relative;
}
@media screen and (max-width: 640px) and (orientation: portrait) {
.socials .paragraph[data-v-67be2ea4] {
        margin-right: 0px;
}
}
.socials .paragraph .mailCTA[data-v-67be2ea4] {
      width: 300px;
      text-align: right;
      position: absolute;
      top: -32px;
      right: 0px;
      opacity: 0;
      -webkit-transition: 0.4s ease;
      transition: 0.4s ease;
}
.socials .paragraph[data-v-67be2ea4]:last-child {
      margin-right: 0px;
      cursor: pointer;
}
.socials .paragraph:last-child:hover .mailCTA[data-v-67be2ea4] {
        opacity: 1;
}
.socials .paragraph[data-v-67be2ea4]:hover {
      opacity: 0.6;
}

.project__container[data-v-09d3e231] {
  position: relative;
  z-index: 2;
}
.project__container .transitionState[data-v-09d3e231] {
    width: 100vw;
    height: 100vh;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: relative;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.project__container .transitionState .img__container[data-v-09d3e231] {
      height: calc(100vh/1.5);
      overflow: hidden;
      border-radius: calc(100vh/15);
      width: 600px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      -webkit-transition: width 1s cubic-bezier(0.86, -0.06, 0.23, 0.99);
      transition: width 1s cubic-bezier(0.86, -0.06, 0.23, 0.99);
}
@media screen and (max-width: 640px) and (orientation: portrait) {
.project__container .transitionState .img__container[data-v-09d3e231] {
          width: calc(100vw - 80px);
          height: calc(100vw - 80px);
}
}
.project__container .transitionState .scroll[data-v-09d3e231] {
      position: absolute;
      bottom: 0px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      -webkit-transition: 0.4s ease;
      transition: 0.4s ease;
}
@media screen and (max-width: 640px) {
.project__container .transitionState .scroll[data-v-09d3e231] {
          bottom: 12%;
}
}
.project__container .transitionState .scroll p[data-v-09d3e231] {
        font-family: "DIN Pro";
        font-size: 20px;
        color: rgba(255, 255, 255, 0.3);
        margin-bottom: 8px;
}
.project__container .transitionState .scroll .svg[data-v-09d3e231] {
        overflow: hidden;
}
.project__container .transitionState .scroll .svg svg[data-v-09d3e231] {
          -webkit-animation: scrollAnimation-data-v-09d3e231 2s cubic-bezier(0.86, -0.06, 0.23, 0.99) infinite;
                  animation: scrollAnimation-data-v-09d3e231 2s cubic-bezier(0.86, -0.06, 0.23, 0.99) infinite;
}
@-webkit-keyframes scrollAnimation-data-v-09d3e231 {
0% {
    -webkit-transform: translateY(48px);
            transform: translateY(48px);
}
100% {
    -webkit-transform: translateY(-48px);
            transform: translateY(-48px);
}
}
@keyframes scrollAnimation-data-v-09d3e231 {
0% {
    -webkit-transform: translateY(48px);
            transform: translateY(48px);
}
100% {
    -webkit-transform: translateY(-48px);
            transform: translateY(-48px);
}
}
.project__container .description[data-v-09d3e231] {
    width: calc(100vw - 140px);
    display: grid;
    grid-template-columns: 1fr 20px 1fr 20px 1fr 20px 1fr 20px 1fr 20px 1fr 20px 1fr 20px 1fr 20px 1fr 20px 1fr 20px 1fr 20px 1fr;
    margin: 200px auto 140px auto;
}
@media screen and (max-width: 640px) and (orientation: portrait) {
.project__container .description[data-v-09d3e231] {
        width: calc(100vw - 48px);
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        grid-template-columns: none;
        margin: 48px auto 48px auto;
}
}
@media screen and (min-width: 2560px) {
.project__container .description[data-v-09d3e231] {
        width: calc(100vw - 480px);
}
}
.project__container .description .clapperboard[data-v-09d3e231] {
      grid-column-start: 1;
      grid-column-end: 10;
}
@media screen and (max-width: 640px) and (orientation: portrait) {
.project__container .description .clapperboard[data-v-09d3e231] {
          grid-column-start: none;
          grid-column-end: none;
          margin-bottom: 40px;
}
}
@media screen and (min-width: 1680px) {
.project__container .description .clapperboard[data-v-09d3e231] {
          grid-column-end: 8;
}
}
.project__container .description .clapperboard .clapperboard__container[data-v-09d3e231] {
        width: 100%;
}
.project__container .description .clapperboard .clapperboard__container div[data-v-09d3e231] {
          font-family: "DIN Pro";
          font-size: 18px;
          color: #FFFFFF;
}
@media screen and (max-width: 640px) and (orientation: portrait) {
.project__container .description .clapperboard .clapperboard__container div[data-v-09d3e231] {
              font-size: 14px;
}
}
.project__container .description .clapperboard .clapperboard__container div span[data-v-09d3e231] {
            font-weight: 500;
            font-style: italic;
            margin-right: 24px;
}
@media screen and (max-width: 640px) and (orientation: portrait) {
.project__container .description .clapperboard .clapperboard__container div span[data-v-09d3e231] {
                margin-right: 8px;
}
}
.project__container .description .clapperboard .clapperboard__container .prod[data-v-09d3e231], .project__container .description .clapperboard .clapperboard__container .role[data-v-09d3e231] {
          border-bottom: 1px solid rgba(255, 255, 255, 0.8);
          padding-bottom: 8px;
}
.project__container .description .clapperboard .clapperboard__container .client[data-v-09d3e231] {
          padding: 8px 0px;
          border-top: 1px solid rgba(255, 255, 255, 0.8);
}
.project__container .description .clapperboard .clapperboard__container .date[data-v-09d3e231] {
          margin-top: 8px;
}
.project__container .description .clapperboard .clapperboard__container .three__numbers[data-v-09d3e231] {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-pack: justify;
              -ms-flex-pack: justify;
                  justify-content: space-between;
}
.project__container .description .clapperboard .clapperboard__container .three__numbers div[data-v-09d3e231] {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
                -ms-flex-direction: column;
                    flex-direction: column;
            -webkit-box-pack: center;
                -ms-flex-pack: center;
                    justify-content: center;
            -webkit-box-align: center;
                -ms-flex-align: center;
                    align-items: center;
            width: 33%;
            border-right: 1px solid rgba(255, 255, 255, 0.8);
}
.project__container .description .clapperboard .clapperboard__container .three__numbers div .number__title[data-v-09d3e231] {
              font-family: "DIN Pro";
              font-weight: 500;
              font-style: italic;
              font-size: 18px;
              color: #FFFFFF;
              margin: 8px 0px;
              text-transform: uppercase;
}
@media screen and (max-width: 640px) and (orientation: portrait) {
.project__container .description .clapperboard .clapperboard__container .three__numbers div .number__title[data-v-09d3e231] {
                  font-size: 14px;
}
}
@media screen and (max-width: 325px) and (orientation: portrait) {
.project__container .description .clapperboard .clapperboard__container .three__numbers div .number__title[data-v-09d3e231] {
                  font-size: 12px;
}
}
.project__container .description .clapperboard .clapperboard__container .three__numbers div .number[data-v-09d3e231] {
              font-family: "DIN Pro";
              font-weight: 500;
              font-size: 40px;
              color: #FFFFFF;
              margin-bottom: 8px;
}
@media screen and (max-width: 640px) and (orientation: portrait) {
.project__container .description .clapperboard .clapperboard__container .three__numbers div .number[data-v-09d3e231] {
                  font-size: 32px;
}
}
@media screen and (max-width: 325px) and (orientation: portrait) {
.project__container .description .clapperboard .clapperboard__container .three__numbers div .number[data-v-09d3e231] {
                  font-size: 24px;
}
}
.project__container .description .clapperboard .clapperboard__container .three__numbers div[data-v-09d3e231]:last-child {
              border-right: none;
}
.project__container .description .synopsis[data-v-09d3e231] {
      grid-column-start: 13;
      grid-column-end: 24;
}
@media screen and (min-width: 1680px) {
.project__container .description .synopsis[data-v-09d3e231] {
          grid-column-end: 22;
}
}
.project__container .description .link[data-v-09d3e231] {
      margin-top: 24px;
      opacity: 0.6;
      -webkit-transition: 0.4s ease;
      transition: 0.4s ease;
}
.project__container .description .link a[data-v-09d3e231] {
        color: #fff;
}
.project__container .description .link a[data-v-09d3e231]:visited {
        color: #fff;
}
.project__container .description .link[data-v-09d3e231]:hover {
        opacity: 1;
}
.project__container .description .subtitle[data-v-09d3e231] {
      margin-bottom: 32px;
}
@media screen and (max-width: 640px) and (orientation: portrait) {
.project__container .description .subtitle[data-v-09d3e231] {
          margin-bottom: 8px;
}
}
.project__container .images[data-v-09d3e231] {
    display: grid;
    grid-template-columns: 1fr calc(100vw - 140px) 1fr;
    margin: 160px auto 0px auto;
}
@media screen and (max-width: 640px) and (orientation: portrait) {
.project__container .images[data-v-09d3e231] {
        grid-template-columns: 1fr calc(100vw - 48px) 1fr;
        margin: 0px auto 0px auto;
}
}
@media screen and (min-width: 2560px) {
.project__container .images[data-v-09d3e231] {
        grid-template-columns: 1fr calc(100vw - 480px) 1fr;
}
}
.project__container .images .image[data-v-09d3e231] {
      grid-column-start: 2;
      grid-column-end: 3;
      margin-bottom: 200px;
}
@media screen and (max-width: 640px) and (orientation: portrait) {
.project__container .images .image[data-v-09d3e231] {
          margin-bottom: 24px;
}
}
.project__container .images .image img[data-v-09d3e231] {
        width: 100%;
        height: auto;
        margin: 16px auto 0px auto;
        display: block;
        -webkit-transition: all 0.6s cubic-bezier(0.44, -0.01, 0.61, 0.97);
        transition: all 0.6s cubic-bezier(0.44, -0.01, 0.61, 0.97);
}
.project__container .images .image.full[data-v-09d3e231] {
        grid-column-start: 1;
        grid-column-end: 4;
}
.project__container .images .image.video[data-v-09d3e231], .project__container .images .image.gif[data-v-09d3e231] {
        grid-column-start: 1;
        grid-column-end: 4;
        background-color: rgba(255, 255, 255, 0.1);
        padding: 50px 0px;
        display: grid;
        grid-template-columns: 1fr 80% 1fr;
}
.project__container .images .image.video video[data-v-09d3e231], .project__container .images .image.video img[data-v-09d3e231], .project__container .images .image.gif video[data-v-09d3e231], .project__container .images .image.gif img[data-v-09d3e231] {
          grid-column-start: 2;
          grid-column-end: 3;
          display: block;
          margin: 0px auto 0px auto;
          width: 100%;
          height: auto;
          -webkit-transition: all 0.6s cubic-bezier(0.44, -0.01, 0.61, 0.97);
          transition: all 0.6s cubic-bezier(0.44, -0.01, 0.61, 0.97);
}
.project__container .images .image.video .subtitle[data-v-09d3e231], .project__container .images .image.gif .subtitle[data-v-09d3e231] {
          grid-column-start: 2;
          grid-column-end: 3;
          margin-bottom: 16px;
}
.project__container .credits[data-v-09d3e231] {
    width: calc(100vw - 140px);
    margin: 0px auto 0px auto;
}
@media screen and (max-width: 640px) and (orientation: portrait) {
.project__container .credits[data-v-09d3e231] {
        width: calc(100vw - 48px);
        margin: 48px auto 0px auto;
}
}
@media screen and (min-width: 2560px) {
.project__container .credits[data-v-09d3e231] {
        width: calc(100vw - 480px);
}
}
.project__container .credits .subtitle[data-v-09d3e231] {
      margin-bottom: 32px;
}
@media screen and (max-width: 640px) and (orientation: portrait) {
.project__container .credits .subtitle[data-v-09d3e231] {
          margin-bottom: 16px;
}
}
.project__container .credits div[data-v-09d3e231] {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
}
.project__container .credits div .credit__name[data-v-09d3e231] {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        margin-right: 24px;
}
@media screen and (max-width: 640px) and (orientation: portrait) {
.project__container .credits div .credit__name[data-v-09d3e231] {
            margin-right: 16px;
}
}
@media screen and (max-width: 325px) and (orientation: portrait) {
.project__container .credits div .credit__name[data-v-09d3e231] {
            margin-right: 8px;
}
}
.project__container .credits div .credit__name p[data-v-09d3e231] {
          font-family: "DIN Pro";
          font-weight: 600;
          font-size: 16px;
          color: #FFFFFF;
          text-transform: uppercase;
          margin-bottom: 16px;
}
@media screen and (max-width: 640px) and (orientation: portrait) {
.project__container .credits div .credit__name p[data-v-09d3e231] {
              font-size: 14px;
              white-space: nowrap;
              margin-bottom: 24px;
}
}
@media screen and (max-width: 325px) and (orientation: portrait) {
.project__container .credits div .credit__name p[data-v-09d3e231] {
              font-size: 12px;
              margin-bottom: 24px;
}
}
.project__container .credits div .credit__role[data-v-09d3e231] {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
}
.project__container .credits div .credit__role p[data-v-09d3e231] {
          font-family: "DIN Pro";
          font-size: 16px;
          color: #FFFFFF;
          height: 19px;
          margin-bottom: 16px;
}
@media screen and (max-width: 640px) and (orientation: portrait) {
.project__container .credits div .credit__role p[data-v-09d3e231] {
              font-size: 14px;
              height: 17px;
              margin-bottom: 24px;
}
}
@media screen and (max-width: 325px) and (orientation: portrait) {
.project__container .credits div .credit__role p[data-v-09d3e231] {
              font-size: 12px;
              height: 14px;
              margin-bottom: 24px;
}
}
.project__container .post__credit[data-v-09d3e231] {
    margin-top: 120px;
    position: relative;
}
@media screen and (max-width: 640px) and (orientation: portrait) {
.project__container .post__credit[data-v-09d3e231] {
        margin-top: 40px;
}
}
.project__container .post__credit .subtitle[data-v-09d3e231] {
      width: calc(100vw - 140px);
      margin: 0px auto 16px auto;
}
@media screen and (max-width: 640px) and (orientation: portrait) {
.project__container .post__credit .subtitle[data-v-09d3e231] {
          width: calc(100vw - 48px);
}
}
.project__container .post__credit__keep[data-v-09d3e231] {
      width: 100%;
      font-family: "Din Pro";
      font-size: 20px;
      text-align: center;
      color: #fff;
      opacity: 0.5;
      position: fixed;
      bottom: 40px;
      -webkit-transition: 1s ease;
      transition: 1s ease;
}
@media screen and (max-width: 640px) {
.project__container .post__credit__keep[data-v-09d3e231] {
          font-size: 14px;
}
}
.project__container .post__credit__keep.hide[data-v-09d3e231] {
        opacity: 0;
}
.project__container .post__credit__container[data-v-09d3e231] {
      height: 100vh;
}

.error[data-v-30c81cb1] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  height: 100vh;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.error__content[data-v-30c81cb1] {
    font-family: "Din Pro";
    font-size: 240px;
    font-weight: 500;
    color: #fff;
}
.error__link a[data-v-30c81cb1] {
    margin-top: 20%;
    font-family: "DIN Pro";
    font-size: 16px;
    color: #fff;
    opacity: 0.6;
    text-decoration: none;
    -webkit-transition: 0.4s ease;
    transition: 0.4s ease;
    cursor: pointer;
    text-align: center;
}
.error__link a[data-v-30c81cb1]:hover {
      opacity: 1;
}
